import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Container,
  Box,
  Layout,
  Grid,
  CaseStudy,
  Section,
  Typography,
  Image,
  Link,
  Button,
} from '../components';
import { appENV } from '../config';
import Flex from './../components/Flex';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { techLanguages } from '../config/data';

const MotionBox = motion(
  styled.div`
    border: 1px solid #152539;
    cursor: pointer;
    background-color: var(--tech-bg);
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  `
);
const techBoxVariants = {
  initial: { opacity: 0, y: 20 },
  hover: { scale: 1.1 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};
const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        caseStudies {
          edges {
            node {
              id
              title
              slug
              case_options {
                caseStudyFeatured
                caseStudyProduction
              }
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  const allCaseStudies = data.wpgraphql.caseStudies.edges;
  const caseStudies = allCaseStudies.filter(item => {
    if (appENV === 'production') {
      return item.node.case_options.caseStudyProduction;
    }

    return true;
  });
  const featuredCase = caseStudies?.find(
    item => item.node.case_options.caseStudyFeatured
  );
  const otherCases = caseStudies?.filter(
    item => !item.node.case_options.caseStudyFeatured
  );

  return (
    <Layout title="Case Studies">
      <Container mt={{ _: 8, md: 9 }}>
        <Box p={{ _: 2, md: 0 }} mb={{ _: 8, md: 0 }}>
          {featuredCase && (
            <CaseStudy type="large" caseStudy={featuredCase.node} />
          )}
        </Box>
        <Box p={{ _: 2, md: 0 }} mt={{ _: 6, md: 10 }}>
          <Grid
            // gap={{ xs: 2, md: 10 }}
            gap="32px"
            gapSm="15px"
            gridRowGap={9}
            gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }}
          >
            {otherCases.map(item => (
              <CaseStudy key={item.node.id} caseStudy={item.node} />
            ))}
          </Grid>
        </Box>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                Ready To Build Your Brand?
              </Typography>
            </Box>
          </Grid>
          <GridContainer>
            <ButtonContainer>
              <Link to="/contact/">
                <Button variant="contained" color="primary" primary>
                  Contact Us
                </Button>
              </Link>
              <Link to="/expertise/">
                <Button variant="contained" color="primary">
                  View All Services
                </Button>
              </Link>
            </ButtonContainer>
          </GridContainer>
        </Section>
      </Container>
    </Layout>
  );
};

export default CaseStudies;
