import styled from 'styled-components';
import {
  flexbox,
  size,
  space,
  layout,
  typography,
  position,
  color,
} from 'styled-system';

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${props => props.gap || '32px'};

  & > div {
    flex: 1 1 calc(20% - ${props => props.gap || '32px'});
    max-width: calc(20% - ${props => props.gap || '32px'});

    @media screen and (max-width: 768px) {
      flex: 1 1 calc(50% - ${props => props.gapSm || props.gap || '32px'});
      max-width: calc(50% - ${props => props.gapSm || props.gap || '32px'});
    }
  }

  ${flexbox}
  ${typography}
  ${size}
  ${space}
  ${layout}
  ${position}
  ${color}
`;

export default Flex;
